import './sidebar.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import logo from '../../../assets/headerlogo.png'

const Sidebar = ({ sidebarDisplay, setSidebarDisplay }) => {
    useEffect(() => {
        setSidebarDisplay(false)
    }, [window.location.pathname])

    return (
        <div className={`admin__sidebar ${sidebarDisplay && 'admin__sidebar__left'}`}>
            <div className="sidebar__header">
                <div className="sidebar__logo">
                    <Link to='/dashboard'>
                        <img src={logo} alt="sidebar__logo" />
                    </Link>
                </div>
                <div className="sidebar__close"
                    onClick={() => setSidebarDisplay(false)}
                >
                    <i className='fa fa-close'></i>
                </div>
            </div>
        </div>
    )
}

export default Sidebar