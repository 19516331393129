import Form from "react-bootstrap/Form"
import { Link, useNavigate } from "react-router-dom"
import logo from "../../../assets/logo.png"

function RegisterSection() {
  const navigate = useNavigate()
  const signupHandle = (e) => {

    const myForm = e.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => console.log("Form successfully submitted"))
      .catch((error) => alert(error));

    e.preventDefault()
    navigate("/dashboard");
  }
  return (
    <>
      <div className="loginWrapper d-flex align-items-center justify-content-center">
        <div className="loginDiv">
          <h1 className="logo text-center">
            <img
              className="loginLogo"
              src={logo}
              alt="Dreambird's Logo"
            />
          </h1>
          <Form
            className="mt-3"
            name="register"
            method="post"
            onSubmit={signupHandle}
          >
            <input type="hidden" name="form-name" value="register" />
            <Form.Group
              className="mb-3"
              controlId="formBasicName"
            >
              <Form.Label className="fw-bold">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                name="name"
                required
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
            >
              <Form.Label className="fw-bold">Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                required
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <p className="text-center">
              <button
                type="submit"
                className="loginBtn"
              >
                Signup
              </button>
            </p>
          </Form>

          <div className="loginSocial d-flex align-items-center justify-content-center mt-4">
            <i className="fa-brands fa-google m-2 fs-0 googleLogo"></i>
            <i className="fa-brands fa-apple m-2 fs-0 appleLogo"></i>
            <i className="fa-brands fa-twitter m-2 fs-0 twitterLogo"></i>
          </div>
          <p className="text-center mt-4">
            <Form.Text className="text-muted">
              Already have an account? <Link to={"/login"}>Login</Link>
            </Form.Text>
          </p>
        </div>
      </div>
    </>
  )
}
export default RegisterSection
