import "./content_managment.css"
import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Modal from "react-bootstrap/Modal"
import image1 from "../../../assets/cate1.png"
import image2 from "../../../assets/customoffer6.png"
import { useNavigate } from "react-router-dom"

function ContentManagmentFilesPanel() {
  const navigate = useNavigate()
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <div className="contentManagmentWrapper">
        <Container fluid>
          <Row className={"contentDivBackground"}>
            <Col
              xl={3}
              md={12}
              xs={12}
            >
              <Row className={""}>
                <Col
                  xl={12}
                  md={12}
                  xs={12}
                >
                  <InputGroup className="mb-3 mt-3">
                    <Form.Control
                      placeholder="Keyword or #tag"
                      aria-describedby="basic-addon2"
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                    >
                      <i
                        className="fa fa-search cardIcons"
                        aria-hidden="true"
                      ></i>
                    </Button>
                  </InputGroup>
                </Col>
                <Row>
                  <Col
                    xl={6}
                    md={6}
                    xs={6}
                  >
                    <h4>Recent</h4>
                  </Col>
                  <Col
                    xl={6}
                    md={6}
                    xs={6}
                  ></Col>
                </Row>
                <Row>
                  <Col
                    xl={12}
                    md={6}
                    xs={6}
                  >
                    <ListGroup>
                      <ListGroup.Item>Swipe</ListGroup.Item>
                      <ListGroup.Item>Romance</ListGroup.Item>
                      <ListGroup.Item>Comedy</ListGroup.Item>
                      <ListGroup.Item>Funny</ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col
              xl={9}
              md={12}
              xs={12}
            >
              <Container fluid>
                <Row className={"cmMainHead"}>
                  <Col
                    xl={9}
                    md={9}
                    xs={6}
                  >
                    <h3 className="cmHead">Uploads as Collections</h3>
                  </Col>
                  <Col
                    xl={3}
                    md={3}
                    xs={6}
                    className="text-end"
                  >
                    <button
                      className="cmAddBtn"
                      onClick={() => setModalShow(true)}
                    >
                      <i className="fa-solid fa-plus"></i> Name
                    </button>
                  </Col>
                </Row>
              </Container>

              <div className="cmContentWrapper">
                <Container fluid>
                  <Row>
                    <Col
                      xl={4}
                      md={4}
                      sm={6}
                    >
                      <div
                        className="mt-4"
                        onClick={() => navigate("/video-splash")}
                      >
                        <Card>
                          <Card.Img
                            className="cmCardImg"
                            variant="top"
                            src={image1}
                          />
                          <Card.Body>
                            <Card.Text>Swipe Fever Trailer</Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col
                      xl={4}
                      md={4}
                      sm={6}
                    >
                      <div
                        className="mt-4"
                        onClick={() => navigate("/video-splash")}
                      >
                        <Card>
                          <Card.Img
                            className="cmCardImg"
                            variant="top"
                            src={image2}
                          />
                          <Card.Body>
                            <Card.Text>Swipe Fever Poster</Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

function MyVerticallyCenteredModal(props) {
  let [selectedFile, setSelectedFile] = useState(null)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Collection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
            <Col
              xl={8}
              md={8}
              xs={12}
            >
              <div className="image-upload w-100 d-flex justify-content-center">
                <label htmlFor="file-input">
                  {selectedFile == null ? (
                    <img
                      style={{
                        width: "100%",
                        border: "2px solid gray",
                        borderRadius: "10px",
                      }}
                      src={
                        "https://dreambird.vercel.app/static/media/quick_upload.fe77131ef077fdf663a90aa58d4e8ff8.svg"
                      }
                    />
                  ) : (
                    <img
                      style={{
                        width: "100%",
                        border: "2px solid gray",
                        borderRadius: "10px",
                      }}
                      src={selectedFile}
                    />
                  )}
                </label>

                <input
                  id="file-input"
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(URL.createObjectURL(e.target.files[0]))
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </Col>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
          </Row>
          <Row>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
            <Col
              xl={8}
              md={8}
              xs={12}
            >
              <Form.Control
                placeholder="Name"
                className={"mt-3"}
              />
            </Col>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ContentManagmentFilesPanel
