import "./content_managment.css"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Modal from "react-bootstrap/Modal"
import fridayNight from "../../../assets/content_managment/Friday Nights.jpg"
import investment from "../../../assets/content_managment/Investments.jpg"
import random from "../../../assets/content_managment/Random.jpg"
import unseen from "../../../assets/marketplace/unseen.jpg"
import vanishing from "../../../assets/marketplace/vanishing.jpg"
import junior from "../../../assets/marketplace/junior.jpg"
import summit from "../../../assets/marketplace/summit.jpg"
import sg50 from "../../../assets/marketplace/50sg.jpg"
import _300 from "../../../assets/marketplace/300.jpg"
import arrival from "../../../assets/marketplace/arrival.jpg"
import borat from "../../../assets/marketplace/borat.jpg"
import boyhood from "../../../assets/marketplace/boyhood.jpg"
import bswan from "../../../assets/marketplace/bswan.jpg"

const projects = [
  {
    title: "The Unseen",
    image: unseen,
    description:
      "A supernatural teen drama series about a group of high school students who have the ability to see and communicate with ghosts and use their powers to help restless spirits cross over and solve the mysteries surrounding their deaths while facing powerful and dangerous forces.",
  },
  {
    title: "The Vanishing Forests",
    image: vanishing,
    description:
      "A documentary that explores the devastating effects of deforestation on the world's forests and wildlife, highlights successful conservation efforts, and serves as a call to action for viewers to become stewards of the planet and work towards a more sustainable future.",
  },
  {
    title: "Junior Chef ",
    image: junior,
    description:
      "A children's cooking show that showcases young talent while teaching young viewers how to cook healthy and delicious meals. Full of fun, culture and cuisines.",
  },
  {
    title: "Summit Seekers",
    image: summit,
    description:
      "A movie about a group of amateur climbers who embark on an epic journey to summit one of the world's most challenging peaks and face numerous obstacles and challenges along the way, testing their friendship, perseverance, and inner strength.",
  },
  {
    title: "Fifty Shades of Grey",
    image: sg50,
    description:
      "Literature student Anastasia Steele's life changes forever when she meets handsome, yet tormented, billionaire Christian Grey.",
  },
  {
    title: "300",
    image: _300,
    description:
      "King Leonidas of Sparta and a force of 300 men fight the Persians at Thermopylae in 480 B.C.",
  },
  {
    title: "Arrival",
    image: arrival,
    description:
      "A linguist works with the military to communicate with alien lifeforms after twelve mysterious spacecrafts appear around the world.",
  },
  {
    title: "Borat",
    image: borat,
    description:
      "Kazakh TV talking head Borat is dispatched to the United States to report on the greatest country in the world. With a documentary crew in tow, Borat becomes more interested in locating and marrying Pamela Anderson.",
  },
  {
    title: "Boyhood",
    image: boyhood,
    description:
      "The life of Mason, from early childhood to his arrival at college.",
  },
  {
    title: "Black Sawan",
    image: bswan,
    description:
      "A committed dancer struggles to maintain her sanity after winning the lead role in a production of Tchaikovsky's 'Swan Lake'.",
  },
]

function ContentManagmentPanel() {
  const navigate = useNavigate()
  const [nfModalShow, setNFtModalShow] = useState(false)

  return (
    <>
      <div className="contentManagmentWrapper">
        <Container fluid>
          <Row className={"contentDivBackground"}>
            <Col
              xl={3}
              md={12}
              xs={12}
            >
              <Row>
                <Col
                  xl={12}
                  md={12}
                  xs={12}
                >
                  <InputGroup className="mb-3 mt-3">
                    <Form.Control
                      placeholder="Keyword or #tag"
                      aria-describedby="basic-addon2"
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                    >
                      <i
                        className="fa fa-search cardIcons"
                        aria-hidden="true"
                      ></i>
                    </Button>
                  </InputGroup>
                </Col>
                <Row>
                  <Col
                    xl={6}
                    md={6}
                    xs={6}
                  >
                    <h4>Recent</h4>
                  </Col>
                  <Col
                    xl={6}
                    md={6}
                    xs={6}
                  >
                    {/*<p className="float-end">All (4)</p>*/}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={12}
                    md={6}
                    xs={6}
                  >
                    <ListGroup>
                      <ListGroup.Item>Swipe</ListGroup.Item>
                      <ListGroup.Item>Romance</ListGroup.Item>
                      <ListGroup.Item>Comedy</ListGroup.Item>
                      <ListGroup.Item>Funny</ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col
              xl={9}
              md={12}
              xs={12}
              className={" "}
            >
              <Container fluid>
                <Row className={"cmMainHead"}>
                  <Col
                    xl={9}
                    md={9}
                    xs={9}
                  >
                    <h3 className="cmHead">Collections</h3>
                  </Col>
                  <Col
                    xl={3}
                    md={3}
                    xs={3}
                    className="text-end"
                  >
                    <button
                      className="cmAddBtn"
                      onClick={() => setNFtModalShow(true)}
                    >
                      <i className="fa-solid fa-plus"></i> Collection
                    </button>
                  </Col>
                </Row>
              </Container>

              <div className="cmContentWrapper">
                <Container fluid>
                  <Row>
                    <Col
                      xl={4}
                      md={4}
                      sm={6}
                    >
                      <div className="mt-4">
                        <Card
                          onClick={() =>
                            navigate("/content-management-files")
                          }
                        >
                          <Card.Img
                            variant="top"
                            className="cmCardImg"
                            src={fridayNight}
                          />
                          <Card.Body>
                            <Card.Title>Friday Nights</Card.Title>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col
                      xl={4}
                      md={4}
                      sm={6}
                    >
                      <div className="mt-4">
                        <Card
                          onClick={() =>
                            navigate("/content-management-files")
                          }
                        >
                          <Card.Img
                            variant="top"
                            className="cmCardImg"
                            src={investment}
                          />
                          <Card.Body>
                            <Card.Title>Investments</Card.Title>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col
                      xl={4}
                      md={4}
                      sm={6}
                    >
                      <div className="mt-4">
                        <Card
                          onClick={() =>
                            navigate("/content-management-files")
                          }
                        >
                          <Card.Img
                            variant="top"
                            className="cmCardImg"
                            src={random}
                          />
                          <Card.Body>
                            <Card.Title>Random</Card.Title>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                  </Row>

                  <div className="marketplace__slider mt-3">
                    <div className="custom__card">
                      <div className="custom__card__header">Projects</div>
                      <div className="marketplace__slider__cont">
                        <Swiper
                          modules={[Navigation]}
                          slidesPerView={"auto"}
                          spaceBetween={15}
                          navigation={true}
                        >
                          {projects.map((item, index) => (
                            <SwiperSlide>
                              <CardProject
                                title={item.title}
                                image={item.image}
                                index={index}
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <NewFolderModel
        show={nfModalShow}
        onHide={() => setNFtModalShow(false)}
      />
    </>
  )
}
const CardProject = ({ index, title, image }) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => navigate("/jobs?type=project")}
      className="marketplace__card custom__card"
      key={index}
    >
      <div className="marketplace__card__img">
        <img
          decoding="async"
          loading="lazy"
          src={image}
          alt=""
        />
      </div>
      <div className="marketplace__card__body">
        <p className="marketplace__card__title">{title}</p>
      </div>
    </div>
  )
}

function NewFolderModel(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
            <Col
              xl={8}
              md={8}
              xs={12}
            >
              <Form.Control
                placeholder=" Collection Name"
                className={"mt-3"}
              />
            </Col>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Create</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ContentManagmentPanel
