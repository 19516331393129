import { useNavigate } from 'react-router-dom'
import { Line, LineChart, ResponsiveContainer } from 'recharts'
import adIcon from '../../../../assets/adIcon.svg'

const Views = () => {
    const navigate = useNavigate();
    const filmData = [
        { clicks: '10', film: "Spiderman" },
        { clicks: '16', film: "James bond" },
        { clicks: '10', film: "Avengers" },
        { clicks: '12', film: "Batman" },
        { clicks: '13', film: "Hungers" },
        { clicks: '11', film: "Hobit" },
        { clicks: '10', film: "Superman" },
        { clicks: '21', film: "Terminator" },
        { clicks: '11', film: "Witcher" },
        { clicks: '23', film: "GOT" }
    ];
    return (
        <div className="custom__card ad__views__cont" onClick={() => navigate('/analytics')}>
            <div className="custom__card__header">
                <div className='card__top__wrapper'>
                    <img src={adIcon} alt="" />
                    <p>Ad Views</p>
                </div>
            </div>
            <div className="ads_views_graph" style={{ height: 'calc(100% - 35px)' }}>
                <ResponsiveContainer width='100%' height="100%">
                    <LineChart data={filmData}>
                        <Line type="monotone" dataKey="clicks" stroke="#C51453" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default Views