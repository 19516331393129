import { useEffect } from "react"
import { useState } from "react"
import "./chat.css"
import ChatCenter from "./components/chat_center"
import ChatLeft from "./components/chat_left"
import ChatRight from "./components/chat_right"
import user1 from "../../../assets/faces/1.jpg"
import user2 from "../../../assets/faces/2.jpg"
import user3 from "../../../assets/faces/3.jpg"
import user4 from "../../../assets/faces/4.jpg"
import user5 from "../../../assets/faces/5.jpg"
import user6 from "../../../assets/faces/6.jpg"
import user7 from "../../../assets/faces/7.jpg"
import user8 from "../../../assets/faces/8.jpg"
import user9 from "../../../assets/faces/9.jpg"
import user10 from "../../../assets/faces/10.jpg"

const time = `${new Date().getHours()}:${new Date().getMinutes()}`
const messagesData = [
  {
    id: 0,
    name: "Jesse Tubalt",
    image: user1,
    lastSeen: "Now",
    isOnline: true,
    unseen: 1,
    messages: [
      {
        type: "right",
        message: "How are you finding dreambird?",
        time,
      },
      {
        type: "left",
        message: "It’s like a dream",
        time,
      },
    ],
  },
  {
    id: 1,
    name: "Kerry Ingleton",
    image: user2,
    lastSeen: "15min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message:
          "Hey Sam, we have another watch party tomorrow at 7pm, would love to have you there?",
        time,
      },
    ],
  },
  {
    id: 2,
    name: "Sarah Keale",
    image: user3,
    lastSeen: "45min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message:
          "I am looking for a filmmaker like you to work on my film, would you consider the following?",
        time,
      },
    ],
  },
  {
    id: 3,
    name: "Lance Potter",
    image: user4,
    lastSeen: "45min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message:
          "Just saw you were interested in my project, can you watch my film and tell me your thoughts?",
        time,
      },
    ],
  },
  {
    id: 4,
    name: "Edi Kamau",
    image: user5,
    lastSeen: "45min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message:
          "Hey man, glad you made it here. I just got my first job and thought you should apply too. Here’s the link.",
        time,
      },
    ],
  },
  {
    id: 5,
    name: "Toby McIntyre",
    image: user6,
    lastSeen: "45min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message:
          "Sam, thank you for being a member of our community, we are holding an event this week and would love to have you there.",
        time,
      },
    ],
  },
  {
    id: 6,
    name: "Hannah Field",
    image: user7,
    lastSeen: "45min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message:
          "Hi Sam, if we have an investment community and we’re wondering if you would like to share your project with us?",
        time,
      },
    ],
  },
  {
    id: 7,
    name: "Jacki Backster",
    image: user8,
    lastSeen: "45min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message:
          "Sam! So lovely to see you here. Are you free to work this Saturday?",
        time,
      },
    ],
  },
  {
    id: 8,
    name: "Robert Trindiel",
    image: user9,
    lastSeen: "45min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message:
          "Hi Sam, nice to see you, thanks for connecting with me! What brought you here?",
        time,
      },
    ],
  },
  {
    id: 9,
    name: "Heidi Hernandez",
    image: user10,
    lastSeen: "45min ago",
    isOnline: false,
    unseen: 0,
    messages: [
      {
        type: "left",
        message: "Have you seen this epic trailer? This movie looks awesome!",
        time,
      },
    ],
  },
]

const Chat = () => {
  const [allMessages, setAllMessages] = useState(messagesData)
  const [selectedChat, setSelectedChat] = useState(messagesData[0])
  const [chatProfileShow, setChatProfileShow] = useState({
    chatCenter: "",
    chatRight: "",
  })
  const [chatShow, setChatShow] = useState({
    chatLeft: "chat__child1__display",
    chatCenter: "",
    ChatRight: "",
  })

  const selectedChatHandle = (id) => {
    setSelectedChat(allMessages[id])
  }

  const chatHandle = (id, message) => {
    setAllMessages((item) =>
      item.map((prev) => {
        if (prev.id !== id) return prev
        else {
          setSelectedChat({
            ...prev,
            unseen: 0,
            messages: [...prev.messages, message],
          })
          return { ...prev, unseen: 0, messages: [...prev.messages, message] }
        }
      }),
    )
  }

  useEffect(() => {
    const chat = document.getElementById("chat__body")
    chat.scrollTop = chat.scrollHeight
  }, [])

  return (
    <div className="dashboard">
      <div className="dashboard__cont chat__cont">
        <ChatLeft
          messages={allMessages}
          chatShow={chatShow}
          setChatShow={setChatShow}
          selectedChatHandle={selectedChatHandle}
        />
        <ChatCenter
          user={selectedChat}
          chatShow={chatShow}
          chatHandle={chatHandle}
          setChatShow={setChatShow}
          chatProfileShow={chatProfileShow}
          setChatProfileShow={setChatProfileShow}
        />
        <ChatRight
          user={selectedChat}
          chatShow={chatShow}
          setChatShow={setChatShow}
          chatProfileShow={chatProfileShow}
          setChatProfileShow={setChatProfileShow}
        />
      </div>
    </div>
  )
}

export default Chat
