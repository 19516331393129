import "./recruitment.css"
import React from "react"
import { useNavigate } from "react-router-dom"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import amazon from "../../../assets/recruitment/amazon.png"
import apple from "../../../assets/recruitment/apple.png"
import disney from "../../../assets/recruitment/disney.webp"
import meta from "../../../assets/recruitment/meta.png"
import netflix from "../../../assets/recruitment/netflix.webp"
import universal from "../../../assets/recruitment/universal.jpeg"

function RecruitmentPanel() {
  const navigate = useNavigate()
  return (
    <>
      <div className="recruitmentWrapper">
        <Container fluid>
          <Row>
            <Col
              xl={3}
              md={4}
              xs={6}
            >
              <Row className="recruitmntInfoPanels">
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="text-center">
                    <i className="fa-solid fa-check recruitmentIcons"></i>
                  </h4>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="recruitmentHead">Applied</h4>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="text-center fw-bold recruitmentHead">12</h4>
                </Col>
              </Row>
            </Col>
            <Col
              xl={3}
              md={4}
              xs={6}
            >
              <Row className="recruitmntInfoPanels">
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="text-center">
                    <i className="fa-solid fa-save recruitmentIcons"></i>
                  </h4>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="recruitmentHead">Saved</h4>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="text-center fw-bold recruitmentHead">34</h4>
                </Col>
              </Row>
            </Col>
            <Col
              xl={3}
              md={4}
              xs={6}
            >
              <Row className="recruitmntInfoPanels">
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="text-center">
                    <i className="fa-solid fa-flag recruitmentIcons"></i>
                  </h4>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="recruitmentHead">Offers</h4>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="text-center fw-bold recruitmentHead">04</h4>
                </Col>
              </Row>
            </Col>
            <Col
              xl={3}
              md={4}
              xs={6}
            >
              <Row className="recruitmntInfoPanels">
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="text-center">
                    <i className="fa-solid fa-pen-to-square recruitmentIcons"></i>
                  </h4>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="recruitmentHead">Posts</h4>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  xs={4}
                >
                  <h4 className="text-center fw-bold recruitmentHead">00</h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col
              xl={12}
              md={12}
              xs={12}
            >
              <Row className="recruitmntDiv recruitmentProInfo">
                <Col
                  xl={2}
                  md={2}
                  xs={2}
                >
                  <h4 className="text-center">
                    <i className="recruitmentIcons fa-solid fa-star"></i>
                  </h4>
                </Col>
                <Col
                  xl={7}
                  md={7}
                  xs={7}
                >
                  <h4 className="recruitmentProHead">
                    See jobs where you'd be a top applicant
                  </h4>
                  <h4 className="recruitmentProSubHead customTextMuted">
                    See jobs where you'd be a top applicant
                  </h4>
                </Col>
                <Col
                  xl={3}
                  md={3}
                  xs={3}
                >
                  <button className="recgoProBtn">Go PRO</button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col
              xl={12}
              md={12}
              xs={12}
            >
              <Row className="recruitmentFilter">
                <Col
                  xl={10}
                  md={10}
                  xs={10}
                >
                  <h4 className="recruitmentFilterHead">
                    Based on your profile and career interests
                  </h4>
                  <h4 className="recruitmentFilterSubHead customTextMuted d-flex">
                    <p className={"recFilterCate"}>UX Designer</p>
                    <p className="filterCircles1 recFilterCate"></p>
                    <p className={"recFilterCate"}>Any Locations</p>
                    <p className="filterCircles2 recFilterCate"></p>
                    <p className={"recFilterCate"}>Any industries</p>
                  </h4>
                </Col>
                <Col
                  xl={1}
                  md={1}
                  xs={1}
                >
                  <p className={"text-center"}>
                    <i className="fa-solid fa-border-all recFilterIconM"></i>
                  </p>
                </Col>
                <Col
                  xl={1}
                  md={1}
                  xs={1}
                >
                  <p className={"text-center"}>
                    <i className="fa-solid fa-sliders recFilterIconM"></i>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row className={"recruitmntInfoDiv recruitmentJobs"}>
            <Col
              xl={4}
              md={4}
              xs={12}
            >
              <div className="mt-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={netflix}
                  />
                  <Card.Body>
                    <Card.Title className={"fw-bold"}>Film producer</Card.Title>
                    <Card.Text>
                      <h5 className="mt-3">Netflix</h5>
                      <p className="customTextMuted">
                        <i className="fa-solid fa-map-location-dot"></i>{" "}
                        California, USA
                      </p>
                    </Card.Text>
                    <Row>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <button
                          onClick={() => navigate("/jobs?type=job")}
                          className={"rectSeeMoreBtn"}
                        >
                          See More
                        </button>
                      </Col>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <p className="text-center fw-bold pt-2">2 days back</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col
              xl={4}
              md={4}
              xs={12}
            >
              <div className="mt-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={apple}
                  />
                  <Card.Body>
                    <Card.Title className={"fw-bold"}>
                      Production manager
                    </Card.Title>
                    <Card.Text>
                      <h5 className="mt-3">Apple</h5>
                      <p className="customTextMuted">
                        <i className="fa-solid fa-map-location-dot"></i>{" "}
                        California, USA
                      </p>
                    </Card.Text>
                    <Row>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <button
                          onClick={() => navigate("/jobs?type=job")}
                          className={"rectSeeMoreBtn"}
                        >
                          See More
                        </button>
                      </Col>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <p className="text-center fw-bold pt-2">2 days back</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col
              xl={4}
              md={4}
              xs={12}
            >
              <div className="mt-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={meta}
                  />
                  <Card.Body>
                    <Card.Title className={"fw-bold"}>Editor</Card.Title>
                    <Card.Text>
                      <h5 className="mt-3">Netsol Pvt. Ltd</h5>
                      <p className="customTextMuted">
                        <i className="fa-solid fa-map-location-dot"></i>{" "}
                        California, USA
                      </p>
                    </Card.Text>
                    <Row>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <button
                          onClick={() => navigate("/jobs?type=job")}
                          className={"rectSeeMoreBtn"}
                        >
                          See More
                        </button>
                      </Col>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <p className="text-center fw-bold pt-2">2 days back</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col
              xl={4}
              md={4}
              xs={12}
            >
              <div className="mt-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={universal}
                  />
                  <Card.Body>
                    <Card.Title className={"fw-bold"}>
                      Director of photography
                    </Card.Title>
                    <Card.Text>
                      <h5 className="mt-3">Universal Pictures</h5>
                      <p className="customTextMuted">
                        <i className="fa-solid fa-map-location-dot"></i>{" "}
                        California, USA
                      </p>
                    </Card.Text>
                    <Row>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <button
                          onClick={() => navigate("/jobs?type=job")}
                          className={"rectSeeMoreBtn"}
                        >
                          See More
                        </button>
                      </Col>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <p className="text-center fw-bold pt-2">2 days back</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col
              xl={4}
              md={4}
              xs={12}
            >
              <div className="mt-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={disney}
                  />
                  <Card.Body>
                    <Card.Title className={"fw-bold"}>Script writer</Card.Title>
                    <Card.Text>
                      <h5 className="mt-3">Disney</h5>
                      <p className="customTextMuted">
                        <i className="fa-solid fa-map-location-dot"></i>{" "}
                        Florida, USA
                      </p>
                    </Card.Text>
                    <Row>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <button
                          onClick={() => navigate("/jobs?type=job")}
                          className={"rectSeeMoreBtn"}
                        >
                          See More
                        </button>
                      </Col>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <p className="text-center fw-bold pt-2">2 days back</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col
              xl={4}
              md={4}
              xs={12}
            >
              <div className="mt-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={amazon}
                  />
                  <Card.Body>
                    <Card.Title className={"fw-bold"}>
                      Music video commissioner
                    </Card.Title>
                    <Card.Text>
                      <h5 className="mt-3">Amazon</h5>
                      <p className="customTextMuted">
                        <i className="fa-solid fa-map-location-dot"></i>{" "}
                        Seattle, Washington
                      </p>
                    </Card.Text>
                    <Row>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <button
                          onClick={() => navigate("/jobs?type=job")}
                          className={"rectSeeMoreBtn"}
                        >
                          See More
                        </button>
                      </Col>
                      <Col
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <p className="text-center fw-bold pt-2">2 days back</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default RecruitmentPanel
